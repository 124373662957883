// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppProvider } from "./context/AppContext";
import { Buffer } from "buffer";
import "./i18n/i18n"; // Asigură-te că inițializezi i18n
// import reportWebVitals from './reportWebVitals';
// import { useTranslation } from "react-i18next";

window.Buffer = Buffer;

// const url = window.location.href;
// const pathSegments = window.location.href.pathname.split("/");
// const { i18n } = useTranslation();

// let lang = "en";
// const langIndex = pathSegments.indexOf("ln");
// if (langIndex !== -1 && pathSegments.length > langIndex + 1) {
//   lang = pathSegments[langIndex + 1];
// }
// i18n.changeLanguage(lang);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
// serviceWorker.register();
// reportWebVitals(console.log);
