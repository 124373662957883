// src/context/AppContext.jsx
import React, {
  createContext,
  useReducer,
  useEffect,
  useCallback,
  useState,
} from "react";
import { initializeSession, downloadNewConfig } from "../utils/api";
import { v4 as uuidv4 } from "uuid";
import useCheckConfigVersion from "../hooks/useCheckConfigVersion";
// import { buildSite, buildPortalSite } from '../utils/buildSite';
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  getColorFromTheme,
} from "../utils/helpers";
import {
  deriveProductsFromConfig,
  updateCartAfterNewConfig,
} from "../utils/helpers";
import { extendTheme, ChakraProvider } from "@chakra-ui/react"; // Importăm ChakraProvider
import { shade, tint } from "polished";
import defaultTheme from "../theme"; // Importăm tema din src/theme/index.js
// import useUpdateConfig from "../hooks/useUpdateConfig"; // Import the custom hook
// import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import {isIP} from "../utils/helpers";

const initialState = {
  sessionID: null,
  appCode: "noAppCode",
  appDomain: "",
  appInfo: null,
  language: "en",
  user: null,
  config: {},
  portal: {},
  configVersion: "0.0.0",
  favorites: [],
  showFavoritesOnly: false,
  showCategoriesOnly: false,
  jumpToCategory: null,
  menuList: [],
  configOBJ: {
    AppLanguages: {},
    ECommerce: {},
    MenuList: [],
    ProductsModules: {},
    VisibleGroupings: {},
    ModifiersGroups: {},
    ModifiersGroupsByParent: {},
    Products: {},
    Extras: {},
    CustomFeatures: {},
    Groupings: {},
    GroupsComboOffer: {},
    Places: {},
    theme: {
      primaryColor: "teal.500",
      buttonColor: "teal",
      fonts: {
        heading: "Roboto, sans-serif",
        body: "Open Sans, sans-serif",
      },
      backgrounds: {
        pageBackground: "gray.50",
        productBoxBackground: "white",
        cartBackground: "gray.100",
        loginModalBackground: "white",
        accountModalBackground: "white",
        mobileFooterBackground: "white",
        footer: "gray.600", // Valoare implicită pentru footer
        header: "white", // Valoare implicită pentru header
        mainPage: "gray.50", // Valoare implicită pentru mainpage
      },
      textColors: {
        productBox: "black",
        cart: "black",
        header: "black",
        footer: "black",
        mobileFooter: "black",
        mainPage: "black",
        CartModal: "black",
        UserModal: "black",
      },
    },
  },
  customTheme: defaultTheme, // Initialize with the default theme
  cart: [],
  isLoadingSession: true, // Pentru inițializarea sesiunii
  isLoadingShop: false, // Pentru încărcarea magazinului
  isLoadingPortal: false, // Pentru încărcarea portalului
  isCartModalOpen: false, // Pentru vizibilitatea modalului coș
  error: null,
};

const AppContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SESSION_ID":
      return { ...state, sessionID: action.payload };
    case "SET_APP_INFO":
      return { ...state, appInfo: action.payload };
    case "SET_APP_CODE":
      return { ...state, appCode: action.payload };
    case "SET_APP_DOMAIN":
      return { ...state, appDomain: action.payload };
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    case "SET_CONFIG":
      return { ...state, config: action.payload };
    case "SET_PORTAL":
      return { ...state, portal: action.payload };
    case "FILTER_BY_CATEGORY":
      // Implementarea filtrării după categorie
      return { ...state, selectedCategory: action.payload };

    case "SET_CONFIG_VERSION":
      return { ...state, configVersion: action.payload };
    case "SET_CONFIG_OBJ":
      return {
        ...state,
        configOBJ: action.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        configOBJ: {
          ...state.configOBJ,
          theme: {
            ...state.configOBJ.theme,
            ...action.payload, // Suprascrie doar proprietățile noi
            backgrounds: {
              ...state.configOBJ.theme.backgrounds,
              ...action.payload.backgrounds, // Suprascrie doar background-urile noi
            },
            textColors: {
              ...state.configOBJ.theme.textColors,
              ...action.payload.textColors, // Suprascrie doar culorile textului noi
            },
          },
        },
      };
    case "ADD_FAVORITE":
      return {
        ...state,
        favorites: [...state.favorites, action.payload.id],
      };
    case "REMOVE_FAVORITE":
      return {
        ...state,
        favorites: state.favorites.filter((id) => id !== action.payload.id),
      };
    case "SET_FAVORITES":
      return {
        ...state,
        favorites: action.payload,
      };

    case "OPEN_CART_MODAL":
      // Poți folosi un state boolean pentru a controla vizibilitatea modalului coș
      return {
        ...state,
        isCartModalOpen: true,
      };

    case "CLOSE_CART_MODAL":
      return {
        ...state,
        isCartModalOpen: false,
      };

    // Gestionarea vizualizării categoriilor și favoritelelor
    case "SET_SHOW_CATEGORIES_ONLY":
      return {
        ...state,
        showCategoriesOnly: action.payload,
      };
    case "SET_SHOW_FAVORITES_ONLY":
      return {
        ...state,
        showFavoritesOnly: action.payload,
      };

    case "SET_JUMP_TO_CATEGORY":
      return {
        ...state,
        jumpToCategory: action.payload,
      };

    case "SET_CURRENT_CATEGORY":
      return {
        ...state,
        currentCategory: action.payload,
      };

    // case "SET_BACKGROUND":
    //   return {
    //     ...state,
    //     configOBJ: {
    //       ...state.configOBJ,
    //       theme: {
    //         ...state.configOBJ.theme,
    //         backgrounds: action.payload,
    //       },
    //     },
    //   };

    // Gestionarea adăugării în coș
    case "ADD_TO_CART":
      const existingItem = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        return {
          ...state,
          cart: state.cart.map((item) =>
            item.id === action.payload.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, { ...action.payload, quantity: 1 }],
        };
      }

    // Gestionarea eliminării din coș
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload.id),
      };

    // Resetarea coșului
    case "RESET_CART":
      return { ...state, cart: [] };
    case "UPDATE_CART_ITEM":
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };

    case "UPDATE_CART_AFTER_CONFIG":
      const updatedCart = updateCartAfterNewConfig(state.cart, state.configOBJ);
      return { ...state, cart: updatedCart };
    case "SET_LOADING_SESSION":
      return { ...state, isLoadingSession: action.payload };
    case "SET_LOADING_SHOP":
      return { ...state, isLoadingShop: action.payload };
    case "SET_LOADING_PORTAL":
      return { ...state, isLoadingPortal: action.payload };

    // case "SET_APP_INFO":
    //   return { ...state, appInfo: { ...state.appInfo, ...action.payload } };
    case "SET_CUSTOM_THEME":
      return { ...state, customTheme: action.payload };

    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        isLoadingSession: false,
        isLoadingShop: false,
        isLoadingPortal: false,
      };
    case "CLEAR_ERROR":
      return { ...state, error: null };
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [customTheme, setCustomTheme] = useState(defaultTheme); // Definim starea pentru tema personalizată

  // const updateConfig = useUpdateConfig(); // Use the custom hook

  const applyConfig = useCallback(
    (newConfig, serverVersion, configKey, language) => {
      // Save configuration and version to localStorage
      setLocalStorage(state.appCode, configKey, newConfig);
      setLocalStorage(state.appCode, `${configKey}-version`, serverVersion);

      dispatch({ type: "SET_CONFIG", payload: newConfig });
      dispatch({ type: "SET_CONFIG_VERSION", payload: serverVersion });

      // Derive config object from the new configuration
      const configObj = deriveProductsFromConfig(newConfig, {
        ...state,
        language,
      });

      dispatch({ type: "SET_CONFIG_OBJ", payload: configObj });

      // Update the theme based on the new config
      if (
        typeof configObj.theme !== "undefined" &&
        Object.keys(configObj.theme).length > 0
      ) {
        const themeSettings = {};
        if (typeof configObj.theme.primaryColor !== "undefined") {
          themeSettings.primaryColor = configObj.theme.primaryColor;
        }

        if (typeof configObj.theme.buttonColor !== "undefined") {
          themeSettings.buttonColor = configObj.theme.buttonColor;
        }
        if (typeof configObj.theme.fonts !== "undefined") {
          themeSettings.fonts = configObj.theme.fonts;
        }

        if (typeof configObj.theme.backgrounds !== "undefined") {
          themeSettings.backgrounds = configObj.theme.backgrounds;
        }
        if (typeof configObj.theme.textColors !== "undefined") {
          themeSettings.textColors = configObj.theme.textColors;
        }

        dispatch({ type: "SET_THEME", payload: themeSettings });

        // Generate the updated theme
        // const primaryColor = getColorFromTheme(themeSettings.primaryColor);

        // Actualizează tema personalizată
        const primaryColor = getColorFromTheme(
          themeSettings.primaryColor,
          defaultTheme
        );

        const updatedTheme = extendTheme({
          ...defaultTheme,
          colors: {
            ...defaultTheme.colors,
            primary: primaryColor,
            primaryDark: shade(0.2, primaryColor),
            primaryLight: tint(0.2, primaryColor),
            button: themeSettings.buttonColor,
            // ... alte culori
          },
          fonts: themeSettings.fonts,
          styles: {
            global: (props) => ({
              body: {
                bg:
                  props && props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mainPage.light
                    : defaultTheme.colors.componentBg.mainPage.dark,
                color:
                  props && props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mainPage.light
                    : defaultTheme.colors.componentText.mainPage.dark,
              },
            }),
          },
          components: {
            Box: {
              variants: {
                productBox: (props) => ({
                  bg:
                    themeSettings.backgrounds.productBoxBackground ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.productBox.light
                      : defaultTheme.colors.componentBg.productBox.dark),
                  color:
                    themeSettings.textColors.productBox ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.productBox.light
                      : defaultTheme.colors.componentText.productBox.dark),
                  borderRadius: "lg",
                  boxShadow: "md",
                  padding: 4,
                }),
                cart: (props) => ({
                  bg:
                    themeSettings.backgrounds.cartBackground ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.cart.light
                      : defaultTheme.colors.componentBg.cart.dark),
                  color:
                    themeSettings.textColors.cart ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.cart.light
                      : defaultTheme.colors.componentText.cart.dark),
                  borderRadius: "lg",
                  boxShadow: "md",
                  padding: 4,
                }),
                header: (props) => ({
                  bg:
                    themeSettings.backgrounds.header ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.header.light
                      : defaultTheme.colors.componentBg.header.dark),
                  color:
                    themeSettings.textColors.header ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.header.light
                      : defaultTheme.colors.componentText.header.dark),
                  boxShadow: "sm",
                  padding: 4,
                }),
                footer: (props) => ({
                  bg:
                    themeSettings.backgrounds.footer ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.footer.light
                      : defaultTheme.colors.componentBg.footer.dark),
                  color:
                    themeSettings.textColors.footer ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.footer.light
                      : defaultTheme.colors.componentText.footer.dark),
                  boxShadow: "sm",
                  padding: 4,
                }),
                mobileFooter: (props) => ({
                  bg:
                    themeSettings.backgrounds.footer ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.mobileFooter.light
                      : defaultTheme.colors.componentBg.mobileFooter.dark),
                  color:
                    themeSettings.textColors.footer ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.mobileFooter.light
                      : defaultTheme.colors.componentText.mobileFooter.dark),
                  boxShadow: "sm",
                  padding: 4,
                }),
                mainPage: (props) => ({
                  bg:
                    themeSettings.backgrounds.mainPage ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.mainPage.light
                      : defaultTheme.colors.componentBg.mainPage.dark),
                  color:
                    themeSettings.textColors.mainPage ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.mainPage.light
                      : defaultTheme.colors.componentText.mainPage.dark),
                  padding: 4,
                }),
              },
            },
            Modal: {
              variants: {
                CartModal: (props) => ({
                  bg:
                    themeSettings.backgrounds.CartModal ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.CartModal.light
                      : defaultTheme.colors.componentBg.CartModal.dark),
                  color:
                    themeSettings.textColors.CartModal ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.CartModal.light
                      : defaultTheme.colors.componentText.CartModal.dark),
                }),
                UserModal: (props) => ({
                  bg:
                    themeSettings.backgrounds.UserModal ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentBg.UserModal.light
                      : defaultTheme.colors.componentBg.UserModal.dark),
                  color:
                    themeSettings.textColors.UserModal ||
                    (props.colorMode === "light"
                      ? defaultTheme.colors.componentText.UserModal.light
                      : defaultTheme.colors.componentText.UserModal.dark),
                }),
              },
            },
            // ... alte componente
          },
        });
        setCustomTheme(updatedTheme); // Actualizează tema personalizată

        // dispatch({ type: "SET_CUSTOM_THEME", payload: updatedTheme });
      }
    },
    [state, dispatch]
  );

  // // Funcția pentru actualizarea configurației
  const updateConfig = useCallback(
    async (serverVersion, configKey) => {
      console.log(
        "Updating config with serverVersion:",
        serverVersion,
        "and configKey:",
        configKey
      );
      try {
        const appID = state.appInfo.AppID;
        const language = state.language;
        const newConfig = await downloadNewConfig(appID, language);

        applyConfig(newConfig, serverVersion, configKey, language);
      } catch (error) {
        console.error("Failed to download config for current language:", error);

        console.error("Failed to update config:", error);
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to update configuration.",
        });
      }
    },
    [state.appInfo, state.language, state.appCode]
  );

  // Derive configOBJ din config
  useEffect(() => {
    const configObj = deriveProductsFromConfig(state.config, state);
    dispatch({ type: "SET_CONFIG_OBJ", payload: configObj });
  }, [state.config]);

  // Actualizează coșul când configOBJ se schimbă
  useEffect(() => {
    dispatch({ type: "UPDATE_CART_AFTER_CONFIG" });
  }, [state.configOBJ]);

  // Inițializarea sesiunii
  useEffect(() => {
    const fetchSession = async () => {
      const envConfig = require("../config/config");

      dispatch({ type: "SET_LOADING_SESSION", payload: true }); // Începe încărcarea sesiunii
      try {
        // Parsează URL pentru a identifica partenerul și limba
        const url = window.location.href;
        const urlObj = new URL(url);

        const pathSegments = urlObj.pathname.split("/").filter(Boolean);
        let appCode = "noAppCode"; // Valoare implicită
        let lang = "en";
        console.log("============= URL:", url);
        console.log("============= pathSegments:", pathSegments);

        // Identificare limbă
        const langIndex = pathSegments.indexOf("ln");
        if (langIndex !== -1 && pathSegments.length > langIndex + 1) {
          lang = pathSegments[langIndex + 1];
        }
        i18n.changeLanguage(lang);
        dispatch({ type: "SET_LANGUAGE", payload: lang });
        console.log("Language set to:", lang);

        // Identificare AppCode sau AppDomain
        const subDomain = urlObj.hostname.split(".")[0];

        console.log("============= subDomain:", subDomain);

        // const agencySubDomains = [
        //   "localhost",
        //   "applegacy.alphatech.technology",
        //   "appstagelegacy.alphatech.technology",
        //   "apps.appy.ro",
        //   "apps-stage.appy.ro",
        //   "online.soloncloud.ro",
        //   "horeca.zynox.ro",
        // ];
        const agencySubDomains = envConfig.agencySubDomains;
        console.log("============= agencySubDomains:", agencySubDomains);
        console.log("============= urlObj.hostname:", urlObj.hostname);
        if (pathSegments[0] === "store") {
          if (agencySubDomains.includes(urlObj.hostname) || isIP(urlObj.hostname)) {
            appCode = pathSegments[1] || "noAppCode";
            dispatch({ type: "SET_APP_CODE", payload: appCode });
            console.log("AppCode set to:", appCode);
          } else {
            const appDomain = subDomain;
            dispatch({ type: "SET_APP_DOMAIN", payload: appDomain });
            console.log("AppDomain set to:", appDomain);
          }
        } else {
          //todo initialize for portal
        }




        // Inițializarea sau preluarea SessionID
        const currentAppCode = agencySubDomains.includes(urlObj.hostname)
          ? appCode
          : "noAppCode";
        let storedSessionID = getLocalStorage(currentAppCode, "SessionID"); // 'appCode-SessionID'
        if (!storedSessionID) {
          const clientSessionID = uuidv4();
          const newSessionID = await initializeSession(clientSessionID);
          setLocalStorage(currentAppCode, "SessionID", newSessionID); // 'appCode-SessionID'
          dispatch({ type: "SET_SESSION_ID", payload: newSessionID });
          console.log("New sessionID initialized:", newSessionID);
        } else {
          dispatch({ type: "SET_SESSION_ID", payload: storedSessionID });
          console.log("Existing sessionID loaded:", storedSessionID);
        }
      } catch (error) {
        console.error("Failed to initialize session");
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to initialize session.",
        });
      } finally {
        dispatch({ type: "SET_LOADING_SESSION", payload: false }); // Încheie încărcarea sesiunii
      }
    };

    fetchSession();
  }, []); // Lista de dependențe goală pentru a preveni apeluri multiple

  // Actualizează tema personalizată când configOBJ.theme se schimbă
  useEffect(() => {
    if (
      state.configOBJ.theme &&
      Object.keys(state.configOBJ.theme).length > 0
    ) {
      const primaryColorString = state.configOBJ.theme.primaryColor;
      const primaryColor = getColorFromTheme(primaryColorString, defaultTheme);

      // Extindem tema cu setările actualizate
      const updatedTheme = extendTheme({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary: primaryColor,
          primaryDark: shade(0.2, primaryColor),
          primaryLight: tint(0.2, primaryColor),
          button: state.configOBJ.theme.buttonColor,
          // ... alte culori
        },
        fonts: state.configOBJ.theme.fonts,
        styles: {
          global: (props) => ({
            body: {
              bg:
                props && props.colorMode === "light"
                  ? defaultTheme.colors.componentBg.mainPage.light
                  : defaultTheme.colors.componentBg.mainPage.dark,
              color:
                props && props.colorMode === "light"
                  ? defaultTheme.colors.componentText.mainPage.light
                  : defaultTheme.colors.componentText.mainPage.dark,
            },
          }),
        },
        components: {
          Box: {
            variants: {
              productBox: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.productBox.light
                    : defaultTheme.colors.componentBg.productBox.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.productBox.light
                    : defaultTheme.colors.componentText.productBox.dark,
                borderRadius: "lg",
                boxShadow: "md",
                padding: 4,
              }),
              cart: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.cart.light
                    : defaultTheme.colors.componentBg.cart.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.cart.light
                    : defaultTheme.colors.componentText.cart.dark,
                borderRadius: "lg",
                boxShadow: "md",
                padding: 4,
              }),
              header: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.header.light
                    : defaultTheme.colors.componentBg.header.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.header.light
                    : defaultTheme.colors.componentText.header.dark,
                boxShadow: "sm",
                padding: 4,
              }),
              footer: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.footer.light
                    : defaultTheme.colors.componentBg.footer.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.footer.light
                    : defaultTheme.colors.componentText.footer.dark,
                boxShadow: "sm",
                padding: 4,
              }),
              mobileFooter: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mobileFooter.light
                    : defaultTheme.colors.componentBg.mobileFooter.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mobileFooter.light
                    : defaultTheme.colors.componentText.mobileFooter.dark,
                boxShadow: "sm",
                padding: 4,
              }),
              mainPage: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mainPage.light
                    : defaultTheme.colors.componentBg.mainPage.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mainPage.light
                    : defaultTheme.colors.componentText.mainPage.dark,
                padding: 4,
              }),
            },
          },
          Modal: {
            variants: {
              CartModal: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.CartModal.light
                    : defaultTheme.colors.componentBg.CartModal.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.CartModal.light
                    : defaultTheme.colors.componentText.CartModal.dark,
              }),
              UserModal: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.UserModal.light
                    : defaultTheme.colors.componentBg.UserModal.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.UserModal.light
                    : defaultTheme.colors.componentText.UserModal.dark,
              }),
            },
          },
        },
      });
      setCustomTheme(updatedTheme); // Actualizează tema personalizată
    }
  }, [state.configOBJ.theme]);

  // Gestionarea favoritelelor
  useEffect(() => {
    // Încarcă favoritele la inițializare
    const loadFavorites = () => {
      if (state.user) {
        // Dacă utilizatorul este autentificat, încărcăm favoritele din user object
        dispatch({
          type: "SET_FAVORITES",
          payload: state.user.favorites || [],
        });
      } else {
        // Dacă nu, încărcăm din localStorage
        const storedFavorites =
          getLocalStorage(state.appCode, "favorites") || [];
        dispatch({ type: "SET_FAVORITES", payload: storedFavorites });
      }
    };

    loadFavorites();
  }, [state.user, state.appCode]);

  useEffect(() => {
    // Salvează favoritele în funcție de autentificare
    if (state.user) {
      // Salvează favoritele în user object (presupunând că ai o funcție pentru asta)
      // saveUserFavorites(state.user.id, state.favorites);
    } else {
      // Salvează în localStorage
      setLocalStorage(state.appCode, "favorites", state.favorites);
    }
  }, [state.favorites, state.user, state.appCode]);

  useEffect(() => {
    if (state.configOBJ?.theme) {
      console.log(
        "configOBJ.theme.backgrounds:",
        state.configOBJ.theme.backgrounds
      );
      // ... restul codului pentru actualizarea temei
    }
  }, [state.configOBJ?.theme]);

  // Compararea și gestionarea versiunii configurației folosind hook-ul
  useCheckConfigVersion(
    state.appInfo?.AppID,
    state.sessionID,
    state.configVersion,
    (serverVersion) => {
      // const appCode = state.appCode || 'noAppCode';
      const lang = state.language || "en";
      const configKey = `config-${lang}`;
      updateConfig(serverVersion, configKey); // configKey include 'config-lang'
    }
  );

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ChakraProvider theme={customTheme}>{children}</ChakraProvider>
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
