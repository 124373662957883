// src/hooks/useCheckConfigVersion.jsx
import { useEffect, useRef } from 'react';
import { getConfigVersion } from '../utils/api'; // Funcție API pentru a obține versiunea config de pe server

const useCheckConfigVersion = (appID, sessionID, localConfigVersion, onConfigUpdate) => {
    const intervalRef = useRef(null);

    const checkAndUpdateConfig = async () => {
        if (!appID || !sessionID) return;

        try {
            const serverConfigVersion = await getConfigVersion(appID, sessionID);
            if (serverConfigVersion && serverConfigVersion !== localConfigVersion) {
                onConfigUpdate(serverConfigVersion);
            }
        } catch (error) {
            console.error('Error checking config version:', error);
        }
    };

    useEffect(() => {
        if (!appID || !sessionID) return;

        // Verificare inițială
        checkAndUpdateConfig();

        // Setare interval pentru verificare la fiecare 5 minute (300000 ms)
        intervalRef.current = setInterval(() => {
            checkAndUpdateConfig();
        }, 1 * 60 * 1000); // 5 minute

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [appID, sessionID, localConfigVersion, onConfigUpdate]);

    return null;
};

export default useCheckConfigVersion;