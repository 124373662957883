// src/MainApp.jsx
import React, { useContext, Suspense, lazy } from "react";
import { AppContext } from "./context/AppContext";
import LoadingIndicator from "./components/Loading/LoadingIndicator";
import { Box, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Portal = lazy(() => import("./pages/Portal"));
const Shop = lazy(() => import("./pages/Store"));

const MainApp = () => {
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const isProduction = process.env.NODE_ENV === "production";
  const { t } = useTranslation();

  // let lang = "en";
  // // Identificare limbă
  // const url = window.location.href;
  // console.log("URL:", url);
  // // const pathSegments = window.location.href.pathname.split("/");
  // const urlObj = new URL(url);

  // const pathSegments = urlObj.pathname.split("/").filter(Boolean);

  // console.log("Path segments:", pathSegments);

  // const langIndex = pathSegments.indexOf("ln");
  // if (langIndex !== -1 && pathSegments.length > langIndex + 1) {
  //   lang = pathSegments[langIndex + 1];
  // }
  // dispatch({ type: "SET_LANGUAGE", payload: lang });
  // console.log("Language set to:", lang);

  let loadingMessage = t("loading.loading");

  if (state.isLoadingSession) {
    loadingMessage = t("loading.loadingSession");
  } else if (state.isLoadingShop) {
    loadingMessage = t("loading.loadingStore");
  } else if (state.isLoadingPortal) {
    loadingMessage = t("loading.loadingPortal");
  } else if (
    Object.keys(state.config).length !== 0 &&
    location.pathname.split("/")[1] === "store"
  ) {
    loadingMessage = t("loading.buildingStore");
  } else if (
    Object.keys(state.portal).length !== 0 &&
    location.pathname.split("/")[1] === "portal"
  ) {
    loadingMessage = t("loading.buildingPortal");
  }

  // Afișează LoadingIndicator dacă oricare din stările de încărcare este activă
  if (state.isLoadingSession || state.isLoadingShop || state.isLoadingPortal) {
    return <LoadingIndicator message={loadingMessage} />;
  }

  if (state.error && !isProduction) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert status="error">
          <AlertIcon />
          <Text>{state.error}</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <Suspense fallback={<LoadingIndicator message="Încărcare..." />}>
      <Routes>
        {/* <Route path="/portal" element={<Portal />} /> */}
        <Route path="/portal/*" element={<Portal />} />
        {/* <Route path="/portal/ln/:lang/*" element={<Portal />} /> */}

        {/* <Route path="/store/:appCode" element={<Shop />} /> */}
        <Route path="/store/:appCode/*" element={<Shop />} />
        {/* <Route path="/store/:appCode/ln/:lang/*" element={<Shop />} /> */}

        <Route path="*" element={<Navigate to="/portal/" replace />} />
      </Routes>
    </Suspense>

    // <Routes>
    //   {/* Ruta pentru pagina generală a magazinelor */}
    //   <Route path="/shops" element={<Shops />} />
    //   <Route path="/shops/*" element={<Shops />} />
    //   <Route path="/shops/ln/:lang/*" element={<Shops />} />

    //   {/* Ruta pentru un magazin specific, cu opțional limbă și alți parametri */}
    //   <Route path="/shop/:appCode" element={<Shop />} />
    //   <Route path="/shop/:appCode/*" element={<Shop />} />
    //   <Route path="/shop/:appCode/ln/:lang/*" element={<Shop />} />

    //   {/* Orice altă rută redirecționează către /shops */}
    //   <Route path="*" element={<Navigate to="/shops" replace />} />
    // </Routes>
  );
};

export default MainApp;
