// src/utils/helpers.js

export const deriveProductsFromConfig = (configXml, state) => {

  const envConfig = require("../config/config");
  console.log("config-------------------", envConfig.ServerURL
    ,configXml );
  const ConfigOBJ = {
    AppLanguages: {},
    ECommerce: {},
    MenuList: [],
    ProductsModules: {},
    VisibleGroupings: {},

    ModifiersGroups: {},
    ModifiersGroupsByParent: {},
    Products: {},
    Extras: {},
    CustomFeatures: {},
    Groupings: {},
    GroupsComboOffer: {},
    theme: {},
    Places: {},
  };

  // Helper function to append a suffix before the file extension
  const appendSuffix = (filename, suffix) => {
    const lastDotIndex = filename.lastIndexOf(".");
    // Dacă nu există punct în numele fișierului, adaugă sufixul la final
    if (lastDotIndex === -1) return `${filename}${suffix}`;
    // Separă numele fișierului în parte înainte și după punct
    const name = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex);
    return `${name}${suffix}${extension}`;
  };

  const prepProduct = (prod, product, imgPath, AppItemID) => {
    if (typeof ConfigOBJ.Products[prod.ID] === "undefined") {
      ConfigOBJ.Products[prod.ID] = prod;
    }

    let position = 1;
    if (product.Categories && product.Categories.length > 0) {
      const categ = product.Categories.find(
        (item) => item["$"].ID === AppItemID
      );
      position = categ ? categ["$"].Position : 1;
    }
    prod.Position = position;
    const photos = prod.Img.split(", ").map((photo) => photo.trim());
    const photo1 = photos[0] || "";
    if (photo1 !== "") {
      const imgVer = prod.ImgVersions ? prod.ImgVersions.split(", ")[0] : "";
      prod.ImgLogoURL = imgPath + photo1 + "?v=" + imgVer;
      prod.ImgLogoURL150 =
        imgPath + appendSuffix(photo1, "_150") + "?v=" + imgVer;
      prod.ImgLogoURL600 =
        imgPath + appendSuffix(photo1, "_600") + "?v=" + imgVer;
    } else {
      prod.ImgLogoURL = "";
      prod.ImgLogoURL150 = "";
      prod.ImgLogoURL600 = "";
    }

    return prod;
  };

  // Verificarea validității configurației
  if (
    !configXml ||
    Object.keys(configXml).length === 0 ||
    !configXml.Application ||
    !configXml.Application.Features ||
    !configXml.Application.Features.Products_WEB ||
    !configXml.Application.Features.Grouping_WEB
  ) {
    console.error("Configurația este invalidă.");
    return ConfigOBJ;
  }

  console.log(`Mediul curent: ${envConfig}`);
  console.log("-------------  Deriving products from config:", configXml);

  const isProduction = process.env.NODE_ENV === "production";

  // const appID = config.Application["$"].AppID;
  const appID = state.appInfo.AppID;
  const agencyName = state.appInfo.AgencyName;

  const MenuList = [];
  const VisibleGroupings = {};
  const ProductsModules = {};

  const allGroupings = {};
  // 1. Filtrarea Grupurilor Vizibile
  Object.entries(configXml.Application.Features.Grouping_WEB).forEach(
    ([key, entry]) => {
      const group = entry["$"];
      allGroupings[group.AppItemID] = group;
    }
  );

  Object.entries(allGroupings).forEach(([key, entry]) => {
    if (String(entry.Status) === "1") {
      const isRootGrouping = entry.GroupingAppItemID === 0;
      const hasValidParentGrouping =
        !isRootGrouping && allGroupings[entry.GroupingAppItemID];

      if (isRootGrouping || hasValidParentGrouping) {
        VisibleGroupings[key] = entry;
      }
    }
  });

  // 2. Crearea Meniului pe Bază de Grupuri și Categorii
  console.log("agencyName", agencyName);  
  console.log("agencyName", agencyName);  

  configXml.Application.Features.Products_WEB.forEach((module) => {
    const mod = module["$"];
    let imgPath = "";
    if (isProduction) {
      imgPath =
        window.origin +
        "/cdn/live/" +
        appID +
        // $.getParam("AppID") +
        "/";
    } else {
      imgPath =
        window.location.protocol +
        "//" +
        envConfig[agencyName].agencySubDomanin +
        "/cdn/live/" +
        appID +
        "/";
    }
    console.log("imgPath", imgPath);
    const { AppItemID, GroupingAppItemID } = mod;

    // Verificăm dacă modulul face parte dintr-un grup vizibil
    if (AppItemID === "0") {
      // Gestionarea produselor extras
      if (module.Product && Array.isArray(module.Product)) {
        module.Product.forEach((product) => {
          const prod = product["$"];
          if (typeof ConfigOBJ.Extras[prod.ID] === "undefined") {
            ConfigOBJ.Extras[prod.ID] = prod;
          }
        });
      }
    } else {
      ProductsModules[AppItemID] = mod;

      if (
        GroupingAppItemID === 0 ||
        (allGroupings[GroupingAppItemID] && GroupingAppItemID !== 0)
      ) {
        mod.GroupPosition = allGroupings[GroupingAppItemID]
          ? allGroupings[GroupingAppItemID].Position
          : 0;
        mod.Products = [];
        // console.log("module", module);

        if (module.Product && module.Product["$"]) {
          // Un singur produs
          const product = module.Product;
          const prod = product["$"];
          mod.Products.push(prepProduct(prod, product, imgPath, AppItemID));
        } else if (Array.isArray(module.Product)) {
          // Mai multe produse
          module.Product.forEach((product) => {
            const prod = product["$"];
            mod.Products.push(prepProduct(prod, product, imgPath, AppItemID));
          });
          MenuList.push(mod);
        }
      }
    }
  });

  // Populează ConfigOBJ cu datele procesate
  ConfigOBJ.MenuList = MenuList;
  ConfigOBJ.ProductsModules = ProductsModules;
  ConfigOBJ.VisibleGroupings = VisibleGroupings;

  console.log("ConfigOBJ", ConfigOBJ);
  return ConfigOBJ;
};

// Funcția pentru a actualiza coșul după o nouă configurație
export const updateCartAfterNewConfig = (cart, configOBJ) => {
  const updatedCart = cart.filter((item) => {
    const product = configOBJ.ProductsModules[item.id];
    if (!product) return false; // Produsul nu mai există
    if (
      product.GroupingAppItemID !== 0 &&
      !configOBJ.VisibleGroupings[product.GroupingAppItemID]
    )
      return false; // Grupul produsului nu este vizibil
    return true;
  });

  return updatedCart;
};

export const setLocalStorage = (appCode, key, value) => {
  const prefixedKey = `${appCode}-${key}`;
  localStorage.setItem(prefixedKey, JSON.stringify(value));
};

export const getLocalStorage = (appCode, key) => {
  const prefixedKey = `${appCode}-${key}`;
  const value = localStorage.getItem(prefixedKey);
  return value ? JSON.parse(value) : null;
};

export const removeLocalStorage = (appCode, key) => {
  const prefixedKey = `${appCode}-${key}`;
  localStorage.removeItem(prefixedKey);
};


export const getColorFromTheme = (colorString, defaultTheme) => {
  if (!colorString) return "#319795"; // default color
  const [colorName, colorShade] = colorString.split(".");
  if (
    defaultTheme.colors[colorName] &&
    defaultTheme.colors[colorName][colorShade]
  ) {
    return defaultTheme.colors[colorName][colorShade];
  }
  // Return a default color if the colorString is invalid
  return "#319795";
};

export const isIP = (hostname) => {
 // Expresie regulată pentru IPv4
 const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  
 // Expresie regulată pentru IPv6
 const ipv6Pattern = /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}(:[0-9a-fA-F]{1,4}){1,5}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,6}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,7}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,8}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,9}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,9}|:))$/;

 return ipv4Pattern.test(hostname) || ipv6Pattern.test(hostname);
};
