// src/utils/api.js
import axios from "axios";
import { parseStringPromise } from "xml2js";
import App from "../App";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Funcția pentru a inițializa sesiunea
export const initializeSession = async (clientSessionID) => {
  console.log("================initializeSession");
  try {
    const response = await axios.post(
      `${API_BASE_URL}app/ActionAPP.svc/InitializeSession`,
      {
        input: JSON.stringify({
          ClientSessionID: clientSessionID,
          AnalyticsInstallID: -2,
          AppUserID: "-1",
        }),
      }
    );
    return response.data.SessionID;
  } catch (error) {
    console.error("Error initializing session:", error);
    throw error;
  }
};

// Funcția pentru a obține informațiile despre aplicație
export const getAppInfo = async (appCode, sessionID, domain, languageCode) => {
  console.log(`-----------------------------  getAppInfo appCode: ${appCode}, sessionID: ${sessionID}, domain: ${domain}, languageCode: ${languageCode}`);
//   return { AppID: 807638, AppCode: "AppyTest2" };
  try {
    var request = {
      AppID: 0,
      AppCode: "",
      AppWebOrderSubdomain: "",
      SessionID: sessionID,
      LanguageCode: languageCode,
    };
    if (domain === "") {
      request.AppCode = appCode;
    } else {
      request.AppWebOrderSubdomain = domain;
    }

    const response = await axios.post(
      `${API_BASE_URL}app/ActionAPP.svc/GetAppInfo`,
      {
        input: JSON.stringify(request),
      }
    );
    return response.data.App;
  } catch (error) {
    console.error("Error fetching app info:", error);
    throw error;
  }
};

// Funcția pentru a obține versiunea configurației
export const getConfigVersion = async ( appID, sessionID) => {
  const payload = {
    input: JSON.stringify({
      SessionID: sessionID,
      AppID: appID,
    }),
  };

//   return "1.1.1";
  try {
    const response = await axios.post(
      `${API_BASE_URL}app/ActionAPP.svc/GetXMLInfo`,
      payload
    );
    return response.data.AppDeploy.AndroidVersion;
  } catch (error) {
    console.error("Error fetching config version:", error);
    throw error;
  }
};

// Funcția pentru a descărca configurația nouă
export const downloadNewConfig = async (appID, language) => {
  // const sessionID = localStorage.getItem("SessionID");

  //   if (!sessionID) {
  //     throw new Error("SessionID not found in localStorage.");
  //   }
//   return {fake:'data'};


  // Construiește URL-ul bazat pe appID și language
  const url = `${API_BASE_URL}cdn/live/${appID}/config4${
    language !== "en" ? "_" + language : ""
  }.xml`;

  console.log(`Downloading config from URL: ${url}`);

  try {
    // Execută cererea GET pentru a obține fișierul XML
    const response = await axios.get(url);
    const xmlData = response.data;

    // Parsează XML-ul în JSON
    const jsonData = await parseStringPromise(xmlData, {
      explicitArray: false,
    });
    console.log("Downloaded config:", jsonData);
    return jsonData;
  } catch (error) {
    console.error("Error downloading new config:", error);
    if(error.code === 'ERR_BAD_REQUEST'){
    // Retry logic
    let attempt = 0;
    while (attempt < 3) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 5 seconds
        const response = await axios.get(url);
        const xmlData = response.data;
        const jsonData = await parseStringPromise(xmlData, {
          explicitArray: false,
        });
        console.log("Downloaded config:", jsonData);
        attempt = 3;
        return jsonData;
      } catch (retryError) {
        attempt++;
        console.error(`Retry attempt ${attempt} failed:`, retryError);
        if (attempt === 3 && language !== "en") {
          // Trying to download the default language
          const fallbackUrl = `${API_BASE_URL}cdn/live/${appID}/config4.xml`;
          console.log(
            `Falling back to default language from URL: ${fallbackUrl}`
          );
          try {
            const fallbackResponse = await axios.get(fallbackUrl);
            const fallbackXmlData = fallbackResponse.data;
            const fallbackJsonData = await parseStringPromise(fallbackXmlData, {
              explicitArray: false,
            });
            console.log("Downloaded fallback config:", fallbackJsonData);
            return fallbackJsonData;
          } catch (fallbackError) {
            console.error("Error downloading fallback config:", fallbackError);
            throw fallbackError;
          }
        }
      }
    }
    }else{
      throw error;
    }   
   

    // throw error;
  }
};

// Funcția pentru a obține informațiile portalului
export const getPortalsInfo = async () => {
  try {
    return {
      Portals: {
        Shops: [
          { ID: 1, PortalKey: "portal1", PortalName: "Shop 1" },
          { ID: 2, PortalKey: "portal2", PortalName: "Shop 2" },
          { ID: 3, PortalKey: "portal3", PortalName: "Shop 3" },
        ],
      },
    };

    const response = await axios.get(
      `${API_BASE_URL}app/ActionAPP.svc/GetPortalsInfo`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching portals info:", error);
    throw error;
  }
};