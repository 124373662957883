// src/App.jsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from './context/AppContext';
import MainApp from './MainApp'; // Componenta care va consuma contextul
import { ColorModeScript } from "@chakra-ui/react";
import customTheme from "./theme"; // Asigură-te că exporti tema ca default
// const config = require('./config');

console.log("-----------------------------------------customtheme");
console.log(customTheme);
const App = () => {
  return (
    <Router>
      <AppProvider>
        <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
        <MainApp />
      </AppProvider>
    </Router>
  );
};

export default App;