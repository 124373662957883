// src/components/Loading/LoadingIndicator.jsx
import React from 'react';
import { Flex, Spinner as ChakraSpinner, Text , Box} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion.create(Box); // Folosește motion.create() în loc de motion()

const LoadingIndicator = ({ message }) => {
    return (
        <Flex direction="column" align="center" justify="center" height="100vh">
        <ChakraSpinner size="xl" />
        <Text mt={4}>{message || "Loading ..."}</Text>
      </Flex>
    );
};

export default LoadingIndicator;

